body {
  padding-top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .main {
    display: flex;
    flex-direction: column;
    font-family: monospace;
    pre {
      line-height: 0.5rem;
      font-size: 0.5rem;
      margin: 0;
    }
  }
}
